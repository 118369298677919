import * as types from "../actionTypes";
import initialState from "./initialState";

export default function staticImageReducer(state = initialState.static_images, action) {
    switch (action.type) {
        case types.LOAD_STATIC_IMAGE_SUCCESS:
            return action.static_images;
        default:
            return state;
    }
}