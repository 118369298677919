import React from "react";

import DivInput from "./DivInput";
import Button from "./common/Button";

function ForgotPasswordForm({
    history,
    users,
    errors,
    onChange,
    handleResetPassword,
    process
}) {

    return (
        <div className="bg-loginscreen">
            <div className="middle-box text-center loginscreen wow fadeIn">
                <div className="loginscreen-border">
                    <div className="login-form">
                        <h3>Forgot Password</h3>
                        <p>Enter your email address, and we'll send you a password reset email.</p>
                        <form onSubmit={handleResetPassword}>
                            <DivInput 
                                divClassName="form-group custom-input" 
                                inputType="text" 
                                inputName="email"
                                inputClassname="form-control"
                                inputPlaceHolder="Email Address"
                                inputOnChange={onChange}
                                inputValue={users.email} 
                                inputError={errors.email}
                            />
                            <Button 
                                type="submit" 
                                className="btn btn-primary block full-width m-b btn-sigin" 
                                text="Reset Password" 
                                process={process} 
                                processMsg="Sending link..." 
                            />
                            <Button 
                                type="button" 
                                className="btn btn-primary block full-width m-b btn-sigin" 
                                text="Go Back"
                                onClick={() => history.goBack()}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className="navy-line"></div>
        </div>
    )
}

export default ForgotPasswordForm;