import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"

import static_images from "./staticImageReducer";
import apiCallsInProgress from "./apiStatusReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["cart"]
}

const rootReducer = combineReducers({
    static_images,
    apiCallsInProgress
});

export default persistReducer(persistConfig, rootReducer);