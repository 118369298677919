import React from "react";

import Input from "./common/Input";

function DivInput({
    divClassName,
    inputId,
    inputType,
    inputName,
    inputLabel,
    inputClassname,
    inputPlaceHolder,
    inputOnChange,
    inputValue,
    inputError,
}) {
    return (
        <div className={divClassName}>
            {
                inputLabel ? <label className="form-label" htmlFor={inputId}>{inputLabel}</label> : ""
            }
            <Input 
                id={inputId}
                type={inputType} 
                name={inputName} 
                className={inputClassname} 
                placeholder={inputPlaceHolder}
                onChange={inputOnChange} 
                value={inputValue} 
                error={inputError}
            />
            <div className="invalid-feedback">
                {inputError}
            </div>
        </div>
    )
}

export default DivInput;