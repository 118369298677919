import React from 'react';
import styled from 'styled-components';

import { static_images } from "../initialData";

const CustomImg = styled.img`
    height: 70px;
    width: 149px;
  `;

const DragonpayLogo = () => {
  return (
    <CustomImg src={static_images.dragonpay} alt='dragonpay_logo'/>
  );
};

export default DragonpayLogo;
