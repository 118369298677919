import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ForceChangePasswordPage from './ForceChangePasswordPage';
import PlanSubscriptionPage from './PlanSubscriptionPage';
import CheckoutPage from './CheckoutPage';
import ScrollToTop from './ScrollToTop';
import AccountPage from './AccountPage';
import DragonpayPostback from './DragonpayPostback';
import ViewReceiptPage from './ViewReceiptPage';
import GoogleAuthCallback from "./GoogleAuthCallback";

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const getCurrentTime = () => {
    axios.get('/get_current_time').then((response) => {
      localStorage.setItem('datetime', new Date(response.data));
    });
  };

  useEffect(() => {
    getCurrentTime();
  }, []);

  return (
    <>
      <ToastContainer
        position={'top-right'}
        autoClose={3000}
        pauseOnHover={false}
        theme={'colored'}
      />
      <ScrollToTop>
        <Switch>
          {/* UNSESSION PATH */}
          <Route path='/signin' component={LoginPage} />
          <Route path='/reset-password' component={ForgotPasswordPage} />
          <Route path='/first-login' component={ForceChangePasswordPage} />
          <Route path='/plan' component={PlanSubscriptionPage} />
          <Route path='/dragonpay/postback/' component={DragonpayPostback} />
          <Route path='/checkout/:slug' component={CheckoutPage} />
          <Route path='/orders/:slug/overview' component={AccountPage} />
          <Route path="/sso/complete/google-oauth2/callback" component={GoogleAuthCallback} />

          {/* SESSION PATH */}
          <Route path='/common/checkout/:slug' component={CheckoutPage} />
          <Route path='/common/orders/:slug/overview' component={AccountPage} />
          <Route path='/common/orders/:slug/receipt' component={ViewReceiptPage} />
        </Switch>
      </ScrollToTop>
    </>
  );
};

export default App;
