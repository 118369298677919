import React from 'react';
import styled from 'styled-components';

import { static_images } from "../initialData";

const CustomImg = styled.img`
  height: 70px;
  width: 95px;
`;

const MayaLogo = () => {
  return (
    <CustomImg src={static_images.maya} alt='maya_logo'/>
  );
};

export default MayaLogo;
