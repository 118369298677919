import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { loadStaticImages } from "../redux/actions/staticImageActions";
import * as api from "../api/api";
import { formDataLogin } from "../initialData";
import { toast } from "react-toastify";

import LoginForm from "./LoginForm";

const static_url = document.getElementsByName("static_url")[0].defaultValue;
const privacy_url = `${static_url}landing_page/pdf/Privacy Notice.pdf`;
const usls_privacy_policy = `${static_url}landing_page/pdf/usls_dp.pdf`;

export function LoginPage({
    loadStaticImages,
    static_images,
    ...props
}) {
    const [users, setUsers] = useState({ ...props.users });
    const [is_otp, setIsOtp] = useState(false)
    const [errors, setErrors] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [privacy_notice_selected_user, setSelectedUser] = useState({});
    const [process, setProcess] = useState(false);
    const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
    const [ssoData, setSsoData] = useState("")

    useEffect(() => {
        if (Object.keys(static_images).length === 0) {
            loadStaticImages();
        }
    }, [])

    useEffect(() => {
        if (ssoData) {
            handleLogin();
        }
    }, [ssoData])

    function receiveDataFromPopup(event) {
        if (event.origin === window.location.origin) {
            const data = {authtoken: event.data};
            api.sso_authentication(data).then(res => {
                if (res) {
                    setSsoData(res);
                }
            }).catch(err => {
                toast.error(err.response.data);
            });
        }
    };

    function signInWithGoogle() {
        const left = (window.innerWidth - 600) / 2;
        const top = (window.innerHeight - 400) / 2;
        window.addEventListener('message', receiveDataFromPopup);
        const popup = window.open('/sso/login/google-oauth2', 'popup', `width=600, height=400, left=${left}, top=${top}`);
        popup?.postMessage({ message: 'Hello from the main window!' }, window.location.origin);
        const checkOAuthStatus = setInterval(function() {
            if (popup.closed) {
                clearInterval(checkOAuthStatus);
                window.removeEventListener('message', receiveDataFromPopup);
            }
        }, 1000);
    }

    function handleChange({target}) {
        const updatedUsers = {...users, [target.name]: target.value};
        setUsers(updatedUsers);
    }

    function validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function renderAccountsList() {
        if (!accounts) {
            return (
                null
            )
        }
        if (accounts.length === 0) {
            return (
                <tr>
                    <td>No list of account found!</td>
                </tr>
            )
        }
        return (
            accounts.map((response) => {
                return (
                    <tr className="table-row" onClick={(event) => handleLogin(event, false, response)} key={response.id}>
                        <td>{response.fullname}</td>
                        <td>{response.type}</td>
                    </tr>
                )
            })
        )
    }

    function userAccountName() {
        if (!accounts) {
            return (
                null
            )
        }
        if (accounts.length === 0) {
            return (
                <span>
                    No list of account found!
                </span>
            )
        }
        return (
            <span>{accounts[0].fullname.split(",")[1]}!</span>
        )
    }

    function userAccountEmail() {
        if (!accounts) {
            return (
                null
            )
        }
        if (accounts.length === 0) {
            return (
                <span>
                    {confirmEmailAddress}
                </span>
            )
        }

        return (
            <span>{confirmEmailAddress}</span>
        )
    }

    function formIsValid() {
        const { email, password, code } = users;
        const errors = {};

        if (!is_otp) {
            if (!email) errors.email = "Email is required.";
            if (email && !validateEmail(email)) errors.email = "Email is invalid.";
            if (!password) errors.password = "Password is required.";
        } else {
            if (!code) errors.code = "Code is required.";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    function handleLogin(event, is_resend, selected_user, is_privacy_agree) {

        if (event) {
            if(is_privacy_agree){
                document.getElementById(event.target.id).disabled = true;
                document.getElementById(event.target.id).innerText = 'Please wait...';
                selected_user = privacy_notice_selected_user
            }
    
            event.preventDefault();
        }

        if (!ssoData) {
            if (!is_resend) {
                if (!formIsValid(users)) return;
                setProcess(true);
            }
        }

        let data = {
            email: users.email,
            password: users.password,
            code: users.code,
            is_resend: is_resend ? true : false,
            is_validate: is_otp && !is_resend ? true : false,
            is_privacy_agree: is_privacy_agree ? true : false,
        };

        if (ssoData) {
            data["authtoken"] = ssoData;
        }

        if (selected_user) {
            data = {
                email: selected_user["email"],
                password: users.password,
                selected_user: selected_user,
                is_resend: is_resend ? true : false,
                is_validate: is_otp && !is_resend ? true : false,
                is_second_attempt: true,
                is_privacy_agree: is_privacy_agree ? true : false,
            };
        }

        api.login(data).then(response => {
            setProcess(false);
            if (response.is_otp) {
                if (is_resend) {
                    toast.success("OTP email sent successfully.");
                }
                setIsOtp(true);
            } else if (response.is_multiple_accounts) {
                window.$("#userAccountsSelection").modal("show");
                setAccounts(response.user);
            } else if (response.show_privacy_notice) {
                window.$("#userPrivacyPolicy").modal("show");
                setSelectedUser(response.selected_user);
            } else {
                if (typeof response === 'string' && response.includes("Account Not Yet Verified. Check your email to verify you account.")) {
                    let get_email = response.split(":")
                    if (get_email.length > 1) {
                        setConfirmEmailAddress(get_email[1])
                    }
                    window.$("#userAccountsSelection").modal("hide");
                    window.$("#unConfirmedAccountModal").modal("show");
                    return;
                }
                toast.success('Signing in...');
                setSsoData("");
                
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('next')) {
                    window.location.href = urlParams.get('next');
                }

                setTimeout(function(){
                    window.location.href = "/dashboard";
                }, 500);
            }
        }).catch(error => {
            setProcess(false);
            if (error.response.data === "No email address.") {
                window.$("#userAccountsSelection").modal("hide");
                window.$("#noEmployeeEmailAddressModal").modal("show");
            } else {
                toast.error(error.response.data);
            }
        });
    }

    function sendEmailConfirmation(event) {
        event.preventDefault();
        const data = {
            email: users.email,
            password: users.password,
            code: users.code
        };
        api.sendEmailConfirmation(data).then(() => {
            setProcess(false);
            toast.success("Confirmation email sent successfully.");
            window.$("#unConfirmedAccountModal").modal("hide");
        }).catch(error => {
            setProcess(false);
            toast.error(error.response.data);
        });
    }

    function openNewTabPrivacy(event) {
        event.preventDefault();
        window.open("/privacy_policy_web/");
    }

    function openNewTabTermsService(event) {
        event.preventDefault();
        window.open("/terms_of_service/");
    }

    return (
        <>
            <LoginForm
                openNewTabPrivacy={openNewTabPrivacy}
                openNewTabTermsService={openNewTabTermsService}
                static_images={static_images}
                is_otp={is_otp}
                users={users}
                errors={errors}
                process={process}
                privacy_url={privacy_url}
                usls_privacy_policy={usls_privacy_policy}
                onChange={handleChange}
                onLogin={handleLogin}
                sendEmailConfirmation={sendEmailConfirmation}
                renderAccountsList={renderAccountsList}
                userAccountName={userAccountName}
                userAccountEmail={userAccountEmail}
                signInWithGoogle={signInWithGoogle}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        static_images: state.static_images,
        users: formDataLogin
    }
}

const mapDispatchToProps = {
    loadStaticImages
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
