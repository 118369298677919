import React from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import MayaLogo from '../svg/MayaLogo';
import DragonpayLogo from '../svg/DragonpayLogo';
import PaymongoLogo from '../svg/PaymongoLogo';

const PaymentTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #373530;
`;
const PaymentsDiv = styled.div`
  display: flex;
  align-items: center;
`;
const RadioInput = styled.input`
  height: 20px;
  width: 20px;
`;
const DivSpacer = styled.div`
  display: block;
  height: 100px;
`;
const PayNowBtn = styled.button`
  height: 60px;
  border-radius: 10px;
  background: #2757ed;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #4f80ff !important;
  }
`;

const PaymentOption = ({ payments, errors, setError, register, onSubmit, isProcessing }) => {
  const validation = (key, message) => (value) => {
    if (!value) {
      setError(key, { type: 'focus' }, { shouldFocus: true });
      toast.error(message);
      return false;
    }
    return true;
  };

  const ConditionalRender = ({ item }) => {
    return (
      <>
        {item.is_active && (
          <PaymentsDiv>
            <RadioInput
              id={item.payment_type}
              type='radio'
              name='payment'
              defaultValue={item.payment_type}
              {...register('payment', { validate: validation('payment', 'Select a payment method') })}
            />
            <label className='ml-3' htmlFor={item.payment_type}>
              {item.payment_type === 'maya' && <MayaLogo />}
              {item.payment_type === 'dragonpay' && <DragonpayLogo />}
              {item.payment_type === 'paymongo' && <PaymongoLogo />}
            </label>
          </PaymentsDiv>
        )}
        {!item.is_active && (
          <>
            <PaymentsDiv>
              <RadioInput
                id={item.payment_type}
                type='radio'
                name='payment'
                defaultValue={item.payment_type}
                disabled={true}
              />
              <label
                className='ml-3'
                htmlFor={item.payment_type}
                data-tooltip-id='payment-tooltip'
                data-tooltip-content='Not available right now.'
                data-tooltip-place='right'
              >
                {item.payment_type === 'maya' && <MayaLogo />}
                {item.payment_type === 'dragonpay' && <DragonpayLogo />}
                {item.payment_type === 'paymongo' && <PaymongoLogo />}
              </label>
            </PaymentsDiv>
            <Tooltip id='payment-tooltip' />
          </>
        )}
      </>
    );
  };

  const renderPayments = () => {
    if (!payments || !payments.length) {
      return null;
    }
    return payments.map((item) => {
      return <ConditionalRender key={item.id} item={item} />;
    });
  };

  return (
    <form className='mb-3 pt-5' onSubmit={onSubmit}>
      <PaymentTitle className='mb-4'>Payment Method</PaymentTitle>
      {renderPayments()}
      <DivSpacer />
      <PayNowBtn className='btn btn-block' type='submit' disabled={isProcessing}>
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </PayNowBtn>
    </form>
  );
};

export default PaymentOption;
