import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import * as api from '../api/api';

import PurchaseStatusCard from './PurchaseStatusCard';
import Sally from '../svg/Sally';
import CheckIcon from '../svg/CheckIcon';

const PlanSubDiv = styled.div`
  padding: 2rem 4rem;
  background: #fefefe;
  flex: 1;
  display: relative;
`;
const PlanSubHeader = styled.h2`
  font-weight: 700;
  font-size: 30px;
  color: #2757ed;
`;
const PlanDetails = styled.span`
  span:first-child {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.02em;
  }
  p {
    font-size: 15px;
  }
`;
const PlanFeatureDiv = styled.div`
  margin-bottom: 0.8rem;
  span {
    font-size: 15px;
  }
`;
const SeparatorDiv = styled.div`
  border: 1px solid #878787;
  margin-bottom: 1rem;
`;
const SignInBtn = styled.button`
  height: 60px;
  border-radius: 10px;
  background: #2757ed;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #4f80ff !important;
  }
`;
const AccountDiv = styled.div`
  padding: 6rem 4rem;
  box-shadow: -10px 0px 25px rgba(39, 87, 237, 0.08);
  flex: 1;
`;
const AccountDivHeader = styled.div`
  h2 {
    font-weight: 700;
  }
  p {
    font-size: 15px;
  }
`;
const AccountPlanDetaisDiv = styled.div`
  margin-bottom: 1rem;

  span {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
  p {
    margin-top: 0.3rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
  p.payment-type, p.subscription-plan {
    font-style: italic;
    color: #2757ed;
  }
  p.payment-status {
    color: #FF3131;
  }
  p.my-account {
    font-size: 18px !important;
  }
`;
const SuccessLabel = styled.p`
  font-weight: 700 !important;
  color: green;
`;
const CancelledLabel = styled.p`
  font-weight: 700 !important;
  color: red;
`;

const AccountPage = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [account, setAccount] = useState({});
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState('new');

  useEffect(() => {
    if (location.pathname.includes('/common/orders/')) {
      setSubscriptionType('renew');
    }
    // wsConnect();
  }, []);

  useEffect(() => {
    if (Object.keys(account).length === 0 || isRefreshData) {
      api
        .loadAccount(slug)
        .then((res) => {
          setAccount(res);
        })
        .catch((err) => {
          setAccount(null);
          toast.error(err);
        })
        .finally(() => {
          setIsRefreshData(false);
        });
    }
  }, [account, isRefreshData]);

  // const wsConnect = () => {
  //   const ws_scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
  //   const url = `${ws_scheme}://${window.location.host}/ws/socket-server/`;
  //   const ws = new WebSocket(url);
  //   ws.onopen  = () => {
  //     console.log('Ready state: ' + ws.readyState);
  //   };
  //   ws.onmessage = (event) => {
  //     console.log(event);
  //     const data = JSON.parse(event.data);
  //     if (data.type === 'payment_api_called') {
  //       setIsRefreshData(true);
  //     }
  //   };
  //   ws.onclose = (event) => {
  //     console.log(
  //       'Socket is closed. Reconnect will be attempted in 1 second.',
  //       event.reason
  //     );
  //     setTimeout(() => {
  //       wsConnect();
  //     }, 1000);
  //   };
  //   ws.onerror = (error) => {
  //     console.error(
  //       'Socket encountered error: ',
  //       error.message,
  //       'Closing socket'
  //     );
  //     ws.close();
  //   };
  // };

  const handleCancelTransaction = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to cancel Purchase?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#808080',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .cancelTransaction(slug)
          .then((response) => {
            toast.success(response.message);
            if (response.title === 'Success') {
              setIsRefreshData(true);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    });
  };

  const lockInPeriod = () => {
    let periodicity = 'Monthly';
    if (account.plans.periodicity_duration) {
      periodicity = `${account.plans.periodicity_duration} year`;
      if (account.plans.periodicity_duration > 1) {
        periodicity = `${account.plans.periodicity_duration} years`;
      }
    }
    return periodicity;
  };

  const YahshuaLogo = () => {
    return (
      <svg
        width='81'
        height='86'
        viewBox='0 0 81 86'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_74_613)'>
          <rect
            x='3'
            y='2'
            width='74.1463'
            height='80'
            rx='10'
            fill='#F3F5FD'
          />
        </g>
        <path
          d='M34.9219 50.4201L34.908 46.1615L34.895 42.1403C34.895 42.1403 44.6723 36.2476 51.1398 32.3497L54.8075 38.4353L34.9219 50.4201Z'
          fill='#0081CD'
        />
        <path
          d='M30.8086 24.6766L34.3525 18.5107L54.7995 30.5623L54.7995 34.1901L54.7995 38.4405L30.8086 24.6766Z'
          fill='#010F7C'
        />
        <path
          d='M27.1309 21.2897L34.3512 18.5113V60.4352L27.1309 64.2223V21.2897Z'
          fill='#02B3BE'
        />
        <defs>
          <filter
            id='filter0_d_74_613'
            x='0'
            y='0'
            width='80.1465'
            height='86'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='1.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.215686 0 0 0 0 0.207843 0 0 0 0 0.188235 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_74_613'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_74_613'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    );
  };

  const CompanyLogo = () => {
    return (
      <svg
        width='65'
        height='69'
        viewBox='0 0 65 69'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_74_649)'>
          <rect
            x='3'
            y='2'
            width='58.3902'
            height='63'
            rx='10'
            fill='#4F80FF'
          />
        </g>
        <path
          d='M32 23.5556V16H13V50H51V23.5556H32ZM20.6 46.2222H16.8V42.4444H20.6V46.2222ZM20.6 38.6667H16.8V34.8889H20.6V38.6667ZM20.6 31.1111H16.8V27.3333H20.6V31.1111ZM20.6 23.5556H16.8V19.7778H20.6V23.5556ZM28.2 46.2222H24.4V42.4444H28.2V46.2222ZM28.2 38.6667H24.4V34.8889H28.2V38.6667ZM28.2 31.1111H24.4V27.3333H28.2V31.1111ZM28.2 23.5556H24.4V19.7778H28.2V23.5556ZM47.2 46.2222H32V42.4444H35.8V38.6667H32V34.8889H35.8V31.1111H32V27.3333H47.2V46.2222ZM43.4 31.1111H39.6V34.8889H43.4V31.1111ZM43.4 38.6667H39.6V42.4444H43.4V38.6667Z'
          fill='white'
        />
        <defs>
          <filter
            id='filter0_d_74_649'
            x='0'
            y='0'
            width='64.3906'
            height='69'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='1.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.215686 0 0 0 0 0.207843 0 0 0 0 0.188235 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_74_649'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_74_649'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    );
  };

  return (
    <div className='d-flex h-100'>
      {Object.keys(account).length ? (
        <>
          <PlanSubDiv>
            <div className='text-center'>
              <PlanSubHeader>YAHSHUA Payroll Solutions</PlanSubHeader>
            </div>
            <div className='d-flex mx-1 mt-5'>
              <div>
                <YahshuaLogo />
              </div>
              <PlanDetails className='mx-4 mb-3'>
                <span>{account.plans.plan}</span>
                <p>
                  Number of Employees: {account.plans.number_of_employees}
                  <br />
                  Lock-in Period: {lockInPeriod()}
                </p>
                <div className='mt-4'>
                  {account.plans.plan_features.map((feature, index) => {
                    return (
                      <PlanFeatureDiv key={index} className='d-flex'>
                        <CheckIcon />
                        <span className='ml-3'>{feature}</span>
                      </PlanFeatureDiv>
                    );
                  })}
                </div>
              </PlanDetails>
            </div>
            <SeparatorDiv></SeparatorDiv>
            <div className='d-flex'>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Payment Type</span>
                <p className='payment-type'>Online Payment - {account.plans.payment}</p>
              </AccountPlanDetaisDiv>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Payment Status</span>
                {!(['Paid', 'Cancelled', 'Failed'].includes(account.plans.status)) && <p className='payment-status'>{account.plans.status}</p>}
                {account.plans.status === 'Paid' && (
                  <SuccessLabel>{account.plans.status}</SuccessLabel>
                )}
                {(account.plans.status === 'Cancelled' ||
                  account.plans.status === 'Failed') && (
                  <CancelledLabel>{account.plans.status}</CancelledLabel>
                )}
              </AccountPlanDetaisDiv>
            </div>
            <div className='d-flex'>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Subscription Plan</span>
                <p className='subscription-plan'>{account.plans.plan}</p>
              </AccountPlanDetaisDiv>
            </div>
            <div className='d-flex mb-5'>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Subscription Start Date</span>
                <p>{account.plans.start_date}</p>
              </AccountPlanDetaisDiv>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Subscription End Date</span>
                <p>{account.plans.end_date}</p>
              </AccountPlanDetaisDiv>
            </div>
            {account.plans.status === 'Paid' && subscriptionType === 'new' && (
              <SignInBtn
                className='btn btn-block'
                onClick={() => {
                  window.location.href = '/signin';
                }}
              >
                Sign In
              </SignInBtn>
            )}
            {subscriptionType === 'renew' && (
              <SignInBtn
                className='btn btn-block'
                onClick={() => {
                  window.location.href = '/dashboard';
                }}
              >
                Go to Dashboard
              </SignInBtn>
            )}
          </PlanSubDiv>
          <AccountDiv>
            <div className='d-flex mx-1 mb-4'>
              <div>
                <CompanyLogo />
              </div>
              <AccountDivHeader className='mx-4'>
                <h2>My Account</h2>
                <p>Reference No. {account.plans.reference_no}</p>
              </AccountDivHeader>
            </div>
            <div className='d-flex'>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Company Name</span>
                <p className='my-account'>{account.user.company_name}</p>
              </AccountPlanDetaisDiv>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Email Address</span>
                <p className='my-account'>{account.user.email}</p>
              </AccountPlanDetaisDiv>
            </div>
            <div className='d-flex'>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Company Address</span>
                <p className='my-account'>{account.user.company_address}</p>
              </AccountPlanDetaisDiv>
              <AccountPlanDetaisDiv className='media-body'>
                <span>Contact Number</span>
                <p className='my-account'>{account.user.contact_number}</p>
              </AccountPlanDetaisDiv>
            </div>

            <div>
              {Object.keys(account.active_plan).length ? (
                <PurchaseStatusCard
                  plan={account.active_plan}
                  handleCancelTransaction={handleCancelTransaction}
                />
              ) : Object.keys(account.plans).length ? (
                <PurchaseStatusCard
                  plan={account.plans}
                  handleCancelTransaction={handleCancelTransaction}
                />
              ) : (
                <div className='card'>
                  <div className='card-body text-center'>
                    <h4>You don't have any active subscription yet</h4>
                  </div>
                </div>
              )}
            </div>
            <div className='text-center mt-4'>
              <Sally />
            </div>
          </AccountDiv>
        </>
      ) : null}
    </div>
  );
};

export default AccountPage;
