import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as api from "../api/api";

const DragonpayPostback = () => {

    const SearchComponent = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const search = SearchComponent();

    useEffect(() => {
        const txnid = search.get('txnid') || "";
        const status = search.get('status') || "";
        let finalStatus = "";
        if (txnid) {
            if (status !== "P") {
                const data = {
                    txnid: txnid,
                    status: finalStatus,
                };
                api.dragonpayAcceptPayment(data).then(res => {
                    if (res) {
                        window.close();
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else {
                setTimeout(() => {
                    window.close();
                }, 1000);
            }
        }
    }, [])

    return (
        <div>Processing ...</div>
    )
}

export default DragonpayPostback;