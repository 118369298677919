import React from 'react';
import styled from 'styled-components';

import { static_images } from '../initialData';

const CustomImg = styled.img`
  height: 100%;
  width: 100%;
`;
const RoundingEarth = () => {
  return <CustomImg src={static_images.globe} alt='globe' />;
};

export default RoundingEarth;
