import React from "react";

function ButtonWithImage({
    className,
    type,
    process,
    processMsg,
    onClick,
    text,
    img
}) {
    return ( 
        <button 
            className={className} 
            type={type} 
            disabled={process} 
            onClick={onClick}
        >
            <img src={img} width="25" height="25" alt="button" />
            {process ? processMsg : text}
        </button>
    )
}

export default ButtonWithImage;