import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';

import * as api from '../api/api';
import classNames from '../helpers/classNames';

import PlanCard from './PlanCard';
import RoundingEarth from '../svg/RoundingEarth';
import Rocket from '../svg/Rocket';

const ParentDiv = styled.div`
  height: 100%;
  position: relative;
`;
const CustomRoundingEarth = styled.div`
  top: 2.7rem;
  z-index: -1;
`;
const CustomRocket = styled.div`
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
`;
const TitleHeader = styled.h1`
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #2757ed;
`;
const SubTitleHeader = styled.h5`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #878787;
`;
const ParentDivToggle = styled.div`
  background: #fbfcff;
  border: 2px solid #e6eaf4;
  border-radius: 10px;
`;
const YearlyInput = styled.input`
  font-size: 20px;
  width: 6rem;
  text-align: center;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 1 !important;
  }
`;
const PeriodicityDiv = styled.div`
  height: 37px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanSubscriptionPage = () => {
  const [plans, SetPlans] = useState([]);
  const [periodicity, setPeriodicity] = useState('monthly');
  const [periodicityDuration, setPeriodicityDuration] = useState(1);
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const jsonString = JSON.stringify({
      email: searchParams.get('email'),
      companyName: searchParams.get('companyName'),
      contactNumber: searchParams.get('contactNumber'),
      companyAddress: searchParams.get('companyAddress'),
      zipCode: searchParams.get('zipCode'),
    });
    localStorage.setItem('formData', jsonString);
  }, []);

  useEffect(() => {
    if (!plans.length) {
      api
        .getPlans()
        .then((res) => {
          res.map((item) => {
            item.isShowMore = false;
            return item;
          });
          SetPlans(res);
        })
        .catch((err) => {
          SetPlans(null);
          console.log(err);
        });
    }
  }, [plans]);

  const changePeriodicity = (periodicity) => {
    setPeriodicity(periodicity);
  };

  const showMore = (id) => {
    let newArray = [...plans];
    let index = newArray.findIndex((item) => item.id == id);
    let updatedObject = { ...newArray[index] };
    updatedObject.isShowMore = true;
    newArray[index] = updatedObject;
    SetPlans(newArray);
  };

  const renderPlans = () => {
    if (!plans || !plans.length) {
      return null;
    }
    return plans.map((item) => {
      return (
        <main key={item.id}>
          <PlanCard
            id={item.id}
            slug={item.slug}
            name={item.name}
            description={item.description}
            price={item.price}
            employeeSlots={item.max_employees}
            features={item.privilege_list}
            periodicity={periodicity}
            periodicityDuration={periodicityDuration}
            isShowMore={item.isShowMore}
            showMore={showMore}
          />
        </main>
      );
    });
  };

  return (
    <ParentDiv>
      <div className='py-5'>
        <div className='text-center mb-3'>
          <TitleHeader>Subscription Plan</TitleHeader>
          <SubTitleHeader>Choose a plan that works best for you and your company.</SubTitleHeader>
        </div>
        <div className='text-center'>
          <ParentDivToggle className='d-inline-block py-3 px-4 mx-auto'>
            <div className='d-flex'>
              <div
                className={classNames(
                  periodicity === 'monthly' ? 'active-toggle-periodicity' : 'inactive-toggle-periodicity',
                  ''
                )}
                onClick={() => changePeriodicity('monthly')}
              >
                <PeriodicityDiv>Monthly</PeriodicityDiv>
              </div>
              <div
                className={classNames(
                  periodicity === 'yearly' ? 'active-toggle-periodicity' : 'inactive-toggle-periodicity',
                  ''
                )}
                onClick={() => changePeriodicity('yearly')}
              >
                <PeriodicityDiv className={classNames(periodicity === 'yearly' ? 'mr-3' : '', '')}>
                  Yearly
                </PeriodicityDiv>
                {periodicity === 'yearly' && (
                  <YearlyInput
                    type='number'
                    value={periodicityDuration}
                    onChange={({ target }) => {
                      let value = parseInt(target.value);
                      if (isNaN(value) || value < 1) {
                        setPeriodicityDuration(1);
                      } else {
                        setPeriodicityDuration(parseInt(target.value));
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </ParentDivToggle>
        </div>
        <div className='d-flex justify-content-center flex-sm-wrap'>{renderPlans()}</div>
      </div>
      <CustomRoundingEarth className='position-absolute'>
        <RoundingEarth />
      </CustomRoundingEarth>
      <CustomRocket className='position-absolute'>
        <Rocket />
      </CustomRocket>
    </ParentDiv>
  );
};

export default PlanSubscriptionPage;
