import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import classNames from '../helpers/classNames';

const RequiredLabel = styled.label`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;

  &::after {
    content: '*';
    color: red;
  }
`;
const InputForm = styled.input`
  border: 1px solid #acb9cb;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.7rem;
`;
const SelectForm = styled.select`
  height: unset !important;
  border: 1px solid #acb9cb;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.7rem;
`;
const NextBtn = styled.button`
  height: 60px;
  border-radius: 10px;
  background: #2757ed;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #4f80ff !important;
  }
`;

const regions = [
  'I',
  'II',
  'III',
  'IV-A',
  'IV-B',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'NCR',
  'CAR',
  'BARMM',
];
const cities = {
  I: [
    'Laoag',
    'Vigan',
    'Candon',
    'San Fernando (La Union)',
    'Alaminos',
    'Batac',
    'Sarrat',
    'Dagupan',
    'Urdaneta',
    'San Carlos',
    'Bayambong',
    'Malasiqui',
  ],
  II: ['Tuguegarao', 'Ilagan', 'Santiago', 'Cauayan', 'Bayombong'],
  III: [
    'Angeles',
    'San Fernando',
    'Balanga',
    'Cabanatuan',
    'Gapan',
    'Malolos',
    'Meycauayan',
    'Palayan',
    'San Jose',
  ],
  'IV-A': [
    'Antipolo',
    'Calamba',
    'San Pablo',
    'Santa Rosa',
    'Batangas City',
    'Lipa',
    'Tanauan',
    'Cavite City',
    'Bacoor',
    'Dasmarinas',
    'Imus',
    'Tagaytay',
    'Trece Martires',
  ],
  'IV-B': ['Puerto Princesa', 'Calapan', 'Roxas', 'San Jose', 'Odiongan'],
  V: ['Legazpi', 'Naga', 'Sorsogon City', 'Ligao', 'Tabaco', 'Iriga'],
  VI: [
    'Iloilo City',
    'Bacolod',
    'Passi',
    'Roxas City',
    'Silay',
    'Talisay',
    'Cadiz',
    'Sagay',
  ],
  VII: [
    'Cebu City',
    'Lapu-Lapu',
    'Mandaue',
    'Danao',
    'Talisay (Cebu)',
    'Tagbilaran (Bohol)',
    'Naga (Cebu)',
  ],
  VIII: ['Tacloban', 'Ormoc', 'Baybay', 'Maasin', 'Borongan'],
  IX: ['Zamboanga City', 'Isabela', 'Dapitan', 'Dipolog', 'Pagadian'],
  X: ['Cagayan de Oro', 'Iligan', 'Malaybalay', 'Valencia', 'Gingoog'],
  XI: ['Davao City', 'Tagum', 'Panabo', 'Digos', 'Mati'],
  XII: [
    'General Santos',
    'Koronadal',
    'Tacurong',
    'Kidapawan',
    'Cotabato City',
  ],
  XIII: ['Butuan', 'Surigao City', 'Tandag', 'Bislig'],
  NCR: [
    'Manila',
    'Quezon City',
    'Caloocan',
    'Las Piñas',
    'Makati',
    'Malabon',
    'Mandaluyong',
    'Marikina',
    'Muntinlupa',
    'Navotas',
    'Parañaque',
    'Pasay',
    'Pasig',
    'San Juan',
    'Taguig',
    'Valenzuela',
  ],
  CAR: ['Baguio', 'Tabuk', 'Bontoc', 'La Trinidad'],
  BARMM: [
    'Cotabato City',
    'Marawi',
    'Lamitan',
    'Maguindanao',
    'Sulu',
    'Tawi-Tawi',
  ],
};

const AddressForm = ({
  register,
  getValues,
  errors,
  setError,
  handleSubmit,
  SetCheckoutProgress,
}) => {
  const [selectedRegion, setSelectedRegion] = useState(
    getValues('region') || ''
  );

  const onSubmit = () => {
    SetCheckoutProgress(2);
  };

  const validation = (key, message) => (value) => {
    if (!value) {
      setError(key, { type: 'focus' }, { shouldFocus: true });
      toast.error(message);
      return false;
    }
    return true;
  };

  return (
    <form className='mb-3' onSubmit={handleSubmit(onSubmit)}>
      <div className='form-group'>
        <RequiredLabel htmlFor='address'>Address</RequiredLabel>
        <InputForm
          id='address'
          type='text'
          className={classNames(
            errors.address ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Address...'
          {...register('address', {
            validate: validation('address', 'Address is required'),
          })}
        />
      </div>
      <div className='form-group'>
        <RequiredLabel htmlFor='region'>Region</RequiredLabel>
        <SelectForm
          id='region'
          className={classNames(
            errors.region ? 'is-invalid' : '',
            'form-control'
          )}
          defaultValue=''
          {...register('region', {
            validate: validation('region', 'Region is required'),
            onChange: ({ target }) => {
              setSelectedRegion(target.value);
            },
          })}
        >
          <option value='' disabled>
            Select Region...
          </option>
          {regions.map((region, index) => {
            return (
              <option key={index} value={region}>
                {region}
              </option>
            );
          })}
        </SelectForm>
      </div>
      <div className='form-group'>
        <RequiredLabel htmlFor='city'>City</RequiredLabel>
        <SelectForm
          id='city'
          className={classNames(
            errors.city ? 'is-invalid' : '',
            'form-control'
          )}
          defaultValue=''
          disabled={!selectedRegion}
          {...register('city', {
            validate: validation('city', 'City is required'),
          })}
        >
          <option value='' disabled>
            Select City...
          </option>
          {selectedRegion &&
            cities[selectedRegion] &&
            cities[selectedRegion].map((city, index) => {
              return (
                <option key={index} value={city}>
                  {city}
                </option>
              );
            })}
        </SelectForm>
      </div>
      <div className='form-group mb-4'>
        <RequiredLabel htmlFor='postal_code'>Postal Code</RequiredLabel>
        <InputForm
          id='postal_code'
          type='text'
          className={classNames(
            errors.postal_code ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Postal Code...'
          {...register('postal_code', {
            validate: validation('postal_code', 'Postal Code is required'),
          })}
        />
      </div>
      <NextBtn className='btn btn-block' type='submit'>
        Next
      </NextBtn>
    </form>
  );
};

export default AddressForm;
