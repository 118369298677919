import React from 'react';
import styled from 'styled-components';

import { static_images } from "../initialData";

const CustomImg = styled.img`
  height: 70px;
  width: 182px;
`;

const PaymongoLogo = () => {
  return (
    <CustomImg src={static_images.paymongo} alt='paymongo_logo'/>
  );
};

export default PaymongoLogo;
