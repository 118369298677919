import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import classNames from '../helpers/classNames';

const RequiredLabel = styled.label`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;

  &::after {
    content: '*';
    color: red;
  }
`;
const InputForm = styled.input`
  border: 1px solid #acb9cb;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.7rem;
`;
const PromotionDiv = styled.div`
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #2c3f58;
  line-height: 18px;
`;
const CustomCheckbox = styled.input`
  height: 19px;
  width: 19px;
`;
const NextBtn = styled.button`
  height: 60px;
  border-radius: 10px;
  background: #2757ed;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #4f80ff !important;
  }
`;

const InformationForm = ({
  register,
  errors,
  setError,
  handleSubmit,
  SetCheckoutProgress,
}) => {
  const onSubmit = () => {
    SetCheckoutProgress(1);
  };

  const validation = (key, message) => (value) => {
    if (!value) {
      setError(key, { type: 'focus' }, { shouldFocus: true });
      toast.error(message);
      return false;
    }
    return true;
  };

  return (
    <form className='mb-3' onSubmit={handleSubmit(onSubmit)}>
      <div className='form-group'>
        <RequiredLabel htmlFor='email'>Email Address</RequiredLabel>
        <InputForm
          id='email'
          type='email'
          className={classNames(
            errors.email ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Email Address...'
          {...register('email', {
            validate: validation('email', 'Email is required'),
          })}
        />
      </div>
      <div className='form-group'>
        <RequiredLabel htmlFor='company_name'>Company Name</RequiredLabel>
        <InputForm
          id='company_name'
          type='text'
          className={classNames(
            errors.company_name ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Company Name...'
          {...register('company_name', {
            validate: validation('company_name', 'Company name is required'),
          })}
        />
      </div>
      <div className='form-group'>
        <RequiredLabel htmlFor='company_address'>Company Address</RequiredLabel>
        <InputForm
          id='company_address'
          type='text'
          className={classNames(
            errors.company_address ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Company Address...'
          {...register('company_address', {
            validate: validation(
              'company_address',
              'Company address is required'
            ),
          })}
        />
      </div>
      <div className='form-group mb-4'>
        <RequiredLabel htmlFor='contact_number'>Contact Number</RequiredLabel>
        <InputForm
          id='contact_number'
          type='text'
          className={classNames(
            errors.contact_number ? 'is-invalid' : '',
            'form-control'
          )}
          placeholder='Enter Contact Number...'
          {...register('contact_number', {
            validate: validation(
              'contact_number',
              'Contact number is required'
            ),
          })}
        />
      </div>
      <PromotionDiv className='form-check d-flex align-items-center mx-2 mb-4'>
        <CustomCheckbox
          id='news_subscription'
          type='checkbox'
          className='form-check-input'
          defaultChecked={true}
          {...register('is_news_subscription', { required: false })}
        />
        <label className='form-check-label ml-3' htmlFor='news_subscription'>
          I’d like to receive helpful promotional and email updates from YAHSHUA
          Payroll Solutions.
        </label>
      </PromotionDiv>
      <NextBtn className='btn btn-block' type='submit'>
        Next
      </NextBtn>
    </form>
  );
};

export default InformationForm;
