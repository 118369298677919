export const LOAD_FEATURES_SUCCESS = "LOAD_FEATURES_SUCCESS";
export const LOAD_PRICING_PLANS_SUCCESS = "LOAD_PRICING_PLANS_SUCCESS";
export const LOAD_SOLUTIONS_SUCCESS = "LOAD_SOLUTIONS_SUCCESS";
export const LOAD_STATIC_IMAGE_SUCCESS = "LOAD_STATIC_IMAGE_SUCCESS";
export const LOAD_FREQUENTLY_ASKED_QUESTION_SUCCESS = "LOAD_FREQUENTLY_ASKED_QUESTION_SUCCESS";
export const LOAD_LEARN_AND_SUPPORT_SUCCESS = "LOAD_LEARN_AND_SUPPORT_SUCCESS";
export const LOAD_JOURNALS_SUCCESS = "LOAD_JOURNALS_SUCCESS";
export const LOAD_NEWS_SUCCESS = "LOAD_NEWS_SUCCESS";
export const LOAD_POSTS_SUCCESS = "LOAD_POSTS_SUCCESS";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const LOAD_PAYMENTS_SUCCESS = "LOAD_PAYMENTS_SUCCESS";

export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";